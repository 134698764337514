import { CheckCircleIcon, ShareIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function CopyPaste({ url = "testing" }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <div>
      <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
        {copied === false ? (
          <ShareIcon className="h-6 w-6" />
        ) : (
          <CheckCircleIcon className="h-6 w-6" />
        )}
      </CopyToClipboard>
    </div>
  );
}
